import { Alert, Text } from "@mantine/core";
import classes from "./my-alert.module.css";
import { useEffect } from "react";
import { IconAlertOctagon } from "@tabler/icons";
import { useColors } from "../../../hooks/colors/useColors";
import { useAlertStore } from "../../config/store/alertStore";
import { mobile, tablet } from "../../../constants/env";

export function MyAlert() {
  const { hideAlert, alert } = useAlertStore((state) => state);
  const { colors } = useColors();

  useEffect(() => {
    if (alert.show) {
      setTimeout(() => {
        hideAlert();
      }, 5000);
    }
  }, [alert.show, hideAlert]);

  return (
    <>
      {alert.show && (
        <div
          className={classes.container}
          style={{ width: !mobile || tablet ? 600 : 300 }}
        >
          <div className={classes.shadowContainer}>
            <Alert
              radius="xl"
              onClose={hideAlert}
              icon={<IconAlertOctagon color={colors.white} />}
              title={
                <Text color={colors.white} size="xl">
                  Erro!
                </Text>
              }
              color="white"
              withCloseButton
              styles={{
                root: {
                  backgroundColor: colors.danger,
                },
                closeButton: {
                  color: colors.white,
                },
              }}
              style={(theme) => ({
                ".mantine-Alert-icon": {
                  marginTop: 0,
                  height: 30,
                  width: 30,
                },
                ".mantine-Alert-root": {
                  borderRadius: 20,
                },
              })}
            >
              <Text size="lg" color={colors.white}>
                {alert.description}
              </Text>
            </Alert>
          </div>
        </div>
      )}
    </>
  );
}
