import { create } from "zustand";

interface AlertState {
  alert: {
    show: boolean;
    description: string;
  };
  showAlert: () => void;
  hideAlert: () => void;
  setAlert: (description: string) => void;
}

export const useAlertStore = create<AlertState>()((set) => ({
  alert: {
    show: false,
    description: "",
  },
  showAlert: () => set((state) => ({ alert: { ...state.alert, show: true } })),
  hideAlert: () => set((state) => ({ alert: { ...state.alert, show: false } })),
  setAlert: (description: string) =>
    set((state) => ({
      alert: { show: true, description: description },
    })),
}));
